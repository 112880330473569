import config from 'tpk-website/config/environment';
import EmberRouter from '@embroider/router';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('about-us', { path: 'a-propos-de-triptyk' });
  this.route('consultancy', { path: 'consultance' });
  this.route('products', { path: 'produits' });
  this.route('work', { path: 'realisations' }, function () {
    this.route('details', { path: ':slug' });
  });
  this.route('cookie-policy', { path: 'politique-cookies' });
  this.route('gcu', { path: 'conditions-generales' });
  this.route('privacy-policy', { path: 'vie-privee' });
});
