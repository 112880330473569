import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'tpk-website/config/environment';

export default class HeadDataService extends Service {
  @tracked title = config.applicationName;
  @tracked description = 'Nouveau site de TRIPTYK';
  @tracked image = 'https://triptyk.eu/assets/images/triptyk-website.png';
  @tracked url = 'https://triptyk.eu';
  @tracked structuredData = null;

  get applicationName() {
    return config.applicationName;
  }

  setStructuredSchema(subRoute, subSubRoute) {
    const baseBreadCrumb = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Accueil',
        item: 'https://triptyk.eu',
      },
    ];
    if (subRoute) {
      baseBreadCrumb.push({
        '@type': 'ListItem',
        position: 2,
        name: subRoute.title,
        item: `https://triptyk.eu/${subRoute.route}`,
      });
    }
    if (subSubRoute) {
      baseBreadCrumb.push({
        '@type': 'ListItem',
        position: 3,
        name: subSubRoute.title,
        item: `https://triptyk.eu/${subRoute.route}/${subSubRoute.route}`,
      });
    }
    this.structuredData = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: baseBreadCrumb,
    });
  }

  setStructuredSchemaForHome() {
    this.structuredData = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Triptyk',
      url: 'https://triptyk.eu',
      logo: 'https://triptyk.eu/assets/images/triptyk-website.png',
      description:
        'TRIPTYK est votre agence de solutions digitales, de consultance et de formation aux métiers du web.',
      sameAs: [
        'https://www.linkedin.com/company/triptyk',
        'https://www.facebook.com/triptykdigital',
      ],
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Chaussée de Binche 177A',
        addressLocality: 'Mons',
        postalCode: '7000',
        addressCountry: 'BE',
      },
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'info@triptyk.eu',
        contactType: 'Service Commercial',
        availableLanguage: ['fr', 'en'],
      },
      department: [
        {
          '@type': 'Organization',
          name: 'Développement Web',
          description:
            "Analyse UX/UI design, création d'ERP, CRM et bien d'autres.",
        },
        {
          '@type': 'Organization',
          name: 'Consultance',
          description: 'Nos experts sont à votre service..',
        },
        {
          '@type': 'Organization',
          name: 'Formation',
          description: 'Nos experts sont à votre service.',
        },
      ],
    });
  }
}

// // DO NOT DELETE: this is how TypeScript knows how to look up your services.
// declare module '@ember/service' {
//   // eslint-disable-next-line no-unused-vars
//   interface Registry {
//     'head-data': HeadDataService;
//   }
// }
